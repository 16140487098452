.header-alerts {  
  display: flex;  
  align-items: center;  
  margin-right: 10px;
  padding-top: 5px;  
}

.alert-icon {  
  position: relative;  
  cursor: pointer;  
  font-size: 20px; /* Match size with nc-icon */  
  color: #66615b; /* Match color with nc-icon */  
  font-family: "Josefin Sans", sans-serif;
  margin-top: 2px;  
}

.alert-icon svg {  
  width: 20px; /* Match size with nc-icon */  
  height: 20px;  
}

.alert-modal .modal-dialog {  
  max-width: 1200px !important;  
  width: 95% !important;  
  margin: 1.75rem auto !important;  
}

/* Modal Container */  

.modal-header .modal-title {  
  width: 100%;  
}

.modal.alert-modal {  
  width: 100% !important;  
}

.alert-modal .modal-content {
  width: 100% !important;  
  background-color: #fff;  
  border-radius: 4px;  
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);  
}  
  
/* Modal Header */  
.alert-modal .modal-header {  
  background-color: #f8f9fa;  
  border-bottom: 2px solid #dee2e6;  
  padding: 1rem;  
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;  
}  
  
/* Modal Body */  
.alert-modal .modal-body {  
  background-color: #fff;  
  max-height: 500px;  
  overflow-y: auto;  
  padding: 1rem;  
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;  
}  

.modal-dialog.alert-modal-dialog {  
  max-width: 1000px !important;  
  width: 90% !important;  
  margin: 1.75rem auto !important;  
}
  
/* Table Styles */  
.alert-modal .alert-table {  
  width: 100% !important;  
  margin-bottom: 1rem !important;
  table-layout: fixed !important;  
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;  
}  
  
.alert-modal .alert-table th {  
  background-color: #f8f9fa;  
  padding: 12px;  
  border: 1px solid #dee2e6;  
  font-weight: 500;  
}  
  
.alert-modal .alert-table td,  
.alert-modal .alert-table th {  
  padding: 12px !important;  
  vertical-align: middle !important;  
  border: 1px solid #dee2e6 !important;  
}  
  
/* Board Name Column */  
.alert-modal .board-name {  
  font-weight: 700;  
  color: #2c2c2c;  
}

.alert-modal .modal-header .text-danger {  
  font-weight: 700;  
  font-size: 1.1em;  
}

.alert-modal.auto-show {  
  z-index: 1060;  
}

.alert-modal .modal-header.offline-alert {  
  background-color: #fff3f3;  
}

.alert-badge {  
  position: absolute;  
  top: -8px;  
  right: -8px;  
  background-color: #dc3545;  
  color: white;  
  border-radius: 50%;  
  padding: 2px;  
  font-size: 11px;  
  min-width: 16px;  
  height: 16px;  
  display: flex;  
  align-items: center;  
  justify-content: center;  
  font-family: "Josefin Sans", sans-serif;  
}
  
.alert-modal .status-badge {  
  padding: 5px 10px !important;  
  border-radius: 4px !important;  
  font-weight: 500 !important;  
  text-align: center !important;  
  display: inline-block !important;  
  min-width: 80px !important;  
  white-space: nowrap !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;  
}
  
.alert-modal .status-badge.online {  
  background-color: #28a745;  
  color: white;  
}  
  
.alert-modal .status-badge.offline {  
  background-color: #dc3545;  
  color: white;  
}

.alert-preferences-container {  
  display: flex;  
  align-items: center;  
  margin-left: 20px; 
  white-space: nowrap; 
}

.alert-preferences-toggle {  
  display: flex;  
  align-items: center;  
  margin: 0;  
  padding: 0;
  gap: 8px;  
}

.alert-checkbox {  
  margin: 0 !important;  
  cursor: pointer;  
  width: 16px !important;  
  height: 16px !important;  
  visibility: visible !important;  
  opacity: 1 !important;  
  position: relative !important;  
}

.alert-label {  
  margin: 0;  
  cursor: pointer;  
  font-size: 0.9rem;  
  white-space: nowrap;  
  user-select: none;  
} 

.alert-preferences-toggle input[type="checkbox"] {  
  appearance: auto !important;  
  -webkit-appearance: auto !important;  
  -moz-appearance: auto !important;  
  position: static !important;  
  opacity: 1 !important;  
  margin: 0 8px 0 0 !important;  
  padding: 0 !important;  
}  
  
/* Ensure proper alignment */  
.alert-preferences-toggle label {  
  display: flex;  
  align-items: center;  
  margin: 0;  
  padding: 0;  
}
  
/* Modal Header Styles */  
.modal-header .modal-title {  
  width: 100%;  
}


.alert-modal .board-name-column {  
  width: 50% !important;  
  padding: 12px !important;  
  word-wrap: break-word !important;  
}  
  
.alert-modal .status-column {  
  width: 15% !important;  
  padding: 12px !important;  
  text-align: center !important;  
}  
  
.alert-modal .timestamp-column {  
  width: 35% !important;  
  padding: 12px !important;  
  white-space: nowrap !important;
  font-weight: 700 !important;  
} 

/* Ensure proper alignment with username */  
.d-flex.align-items-center {  
  height: 100%;  
  display: flex;  
  align-items: center;  
}  
  
/* Match hover effect with user icon */  
.alert-icon:hover {  
  opacity: 0.85;  
}

/* Higher specificity selectors */  
body .modal.alert-modal .modal-dialog,  
div[role="dialog"].alert-modal .modal-dialog {  
  max-width: 1000px !important;  
  width: 90% !important;  
  margin: 1.75rem auto !important;  
}  
  
/* Force modal content width */  
body .modal.alert-modal .modal-dialog .modal-content,  
div[role="dialog"].alert-modal .modal-dialog .modal-content {  
  width: 100% !important;  
  min-width: 1000px !important;  
}

.alert-preferences {  
  margin-left: 20px;  
}  
  
.alert-preferences label {  
  color: inherit;  
  margin-bottom: 0;  
  cursor: pointer;  
  font-size: 0.9rem;  
  white-space: nowrap;  
}

/* Responsive Design */  
@media (max-width: 992px) {  
  .alert-modal .modal-dialog {  
    max-width: 95% !important;  
    margin: 1rem auto;  
  }  
}  
  
@media (max-width: 768px) {  
  .alert-modal .modal-dialog {  
    max-width: 100% !important;  
    margin: 0.5rem;  
  }
  .alert-modal .alert-table th:nth-child(3),  
  .alert-modal .alert-table td:nth-child(3) {  
    width: 35%;  
  }  
}

@media (max-width: 768px) {  
  .modal-header-content {  
    flex-direction: column;  
    align-items: flex-start;  
  }  
  
  .alert-preferences-container {  
    margin-top: 8px;  
    margin-left: 0;  
  }  
  
  .modal-title-section {  
    font-size: 0.95rem;  
  }  
  
  .alert-label {  
    font-size: 0.85rem;  
  }  
  
  .status-badge {  
    font-size: 0.8rem;  
    min-width: 70px;  
    padding: 3px 6px;  
  }  
  
  /* Adjust table for mobile */  
  .board-name-column {  
    min-width: 150px;  
  }  
  
  .status-column {  
    min-width: 80px;  
  }  
  
  .timestamp-column {  
    min-width: 140px;  
  }  
}


/* Dark Theme Support */  
.dark-theme .alert-modal .modal-content {  
  background-color: #2c2c2c;  
  color: #ffffff;  
}  
  
.dark-theme .alert-modal .modal-header {  
  background-color: #343a40;  
  border-bottom-color: #454d55;  
}  
  
.dark-theme .alert-modal .modal-body {  
  background-color: #2c2c2c;  
}  
  
.dark-theme .alert-modal .alert-table th {  
  background-color: #343a40;  
  border-color: #454d55;  
}  
  
.dark-theme .alert-modal .alert-table td {  
  border-color: #454d55;  
}  
  
.dark-theme .alert-modal .board-name {  
  color: #ffffff;  
} 

.modal-header-responsive {  
  padding: 1rem;  
}

.modal-header-content {  
  width: 100%;  
  display: flex;  
  flex-direction: row;  
  justify-content: space-between;  
  align-items: center;  
  flex-wrap: wrap;  
  gap: 10px;  
}

.modal-title-section {  
  font-size: 1rem;  
  flex: 1;  
  min-width: 200px;  
}

/* Table Styles */  
.table-responsive {  
  overflow-x: auto;  
  -webkit-overflow-scrolling: touch;  
}  
  
.alert-table {  
  margin-bottom: 0;  
  min-width: 600px; /* Ensure minimum width for readability */  
}  
  
.board-name-column {  
  min-width: 200px;  
}  
  
.status-column {  
  min-width: 100px;  
  text-align: center;  
}  
  
.timestamp-column {  
  min-width: 180px;  
}

/* Status Badge Styles */  
.status-badge {  
  padding: 4px 8px;  
  border-radius: 4px;  
  font-size: 0.85rem;  
  font-weight: 500;  
  display: inline-block;  
  min-width: 80px;  
  text-align: center;  
}

.status-badge.online {  
  background-color: #28a745;  
  color: white;  
}  
  
.status-badge.offline {  
  background-color: #dc3545;  
  color: white;  
}